body {
    font-family: 'Roboto', sans-serif;
}

html, body, #root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.logo-font {
    font-family: 'LogoFont', 'Roboto', sans-serif;
}

#root textarea, #root input, #root button {
    outline: none;
}

.baustein-client-widget > div {
    z-index: 2;
}


